<template>
  <div class="reason-info-card mx-auto">
    <template>
      <div class="info-card-content">
        <img
          :src="require(`@/assets/images/icons/ic_${icon}.svg`)"
          class="col-2 p-0"
        />
        <p
          class="lh-24 mb-0 card-title d-flex justify-content-start col-8 text-left p-0"
        >
          <b>{{ title }}</b>
        </p>
        <img
          v-b-toggle="`subtitle-${index}`"
          src="@/assets/images/icons/ic_caret_down.svg"
          class="down-icon col-2 ml-2"
        />
      </div>
      <b-collapse :id="`subtitle-${index}`" visible>
        <div class="wrapper">
          <ul class="font-weight-thin subtitle-text">
            <li v-for="(subtitle, id) of subtitles" :key="id">
              {{ subtitle }}
            </li>
          </ul>
        </div>
      </b-collapse>
    </template>
  </div>
</template>
<script>
export default {
  name: "ReasonInfoCard",
  props: {
    icon: {
      type: String,
      default: () => "",
    },
    title: {
      type: String,
      default: () => "",
    },
    subtitles: {
      type: Array,
    },
    index: {
      type: Number,
    },
  },
  methods: {
    active(num) {
      this.questions[num].isActive = !this.questions[num].isActive;
    },
  },
};
</script>
<style lang="scss">
.reason-info-card {
  margin-bottom: 20px;
  background-color: #fff;
  border-radius: 10px;
  .info-card-content {
    height: inherit;
    width: 100%;
    display: flex;
    align-items: center;
    background-color: #2bb673;
    border-radius: 10px;
    .card-title {
      color: white;
      white-space: nowrap;
      font-size: 15px;
    }
    img {
      width: 45px;
      height: inherit;
      border-radius: 10px;
    }
    .down-icon {
      width: 20px;
    }
  }
  .subtitle-text {
    display: block;
    background-color: #fff;
    border-radius: 0 0 10px 10px;
    padding: 10px;
    font-size: 16px;
    text-align: left;
    margin: 0;
    padding-inline-start: 35px;
    box-shadow: 0 2px rgba(0, 0, 0, 0.1);
  }
  .not-collapsed {
    transform: rotate(180deg);
    transition: ease-in-out .3s;
  }
  .collapsed {
    transition: ease-in-out .3s;
  }
  @media screen and(max-width: 350px) {
    .info-card-content .card-title {
      font-size: 14px;
    }
    .subtitle-text {
      font-size: 14px;
    }
  }
}
</style>
